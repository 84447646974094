import coverImage from './../assets/form-banner.png'
import profileImage from './../assets/profileImage.png'
import Button from './Button'
import Input from './Input'
import { useRef } from 'react'


export default function Form() {
  const form = useRef()

  const sendEmail = () => {

  }
  return (
    <>
      <div className="flex h-full">
        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          <div className="relative z-0 flex flex-1 overflow-hidden">
            <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
              <article>
                <div>
                  <div>
                    <img className="h-24 w-full object-cover lg:h-28" src={coverImage} alt="" />
                  </div>
                  <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                      <div className="w-full flex justify-center">
                        <img
                          className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                          src={profileImage}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                      <h1 className="truncate text-2xl text-center font-bold text-gray-900">Send Message</h1>
                      <p className="text-sm mt-2 text-gray-500 text-center px-4 md:px-20">
                        I would love to work with you. Contact me today to learn more and schedule your first session.
                      </p>
                    </div>
                    <>
                      <div className="md:col-span-2 md:mt-0">
                        <form ref={form} onSubmit={sendEmail} method="POST">
                          <div className="py-5">
                            <div className="w-full grid grid-cols-6 gap-6">
                              <Input
                                label="Fullname"
                                name="fullname"
                                inputLength="medium"
                                placeholder="Jane Doe"
                                type="text"
                                field="input"
                                autoComplete="true"
                              />

                              <Input
                                label="Phone number"
                                name="phonenumber"
                                inputLength="medium"
                                placeholder="(+1) XXX XXX XXXX"
                                type="tel"
                                field="input"
                                autoComplete="true"
                              />

                              <Input
                                label="Email"
                                name="email"
                                inputLength="large"
                                placeholder="you@example.com"
                                type="email"
                                field="input"
                                autoComplete="true"
                              />

                              <Input
                                label="Message"
                                name="message"
                                inputLength="large"
                                placeholder="enter you message here..."
                                type="email"
                                field="textarea"
                                autoComplete="true"
                              />
                              <div className='col-span-6'>
                                <Button type={'secondary'} text='Subscribe' />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  </div>
                </div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}