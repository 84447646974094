import React from "react";

const Button = ({ type, text, onClick, children }) => {
  return (
    <>
      {type === "primary" ? (
        <>
          <button
            onClick={onClick}
            className={`py-4 px-10 w-full rounded-lg font-bold text-darkBlue bg-lightGreen hover:bg-darkBlue hover:text-lightGreen transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-10 duration-300 ${children}`}
          >
            {text}
          </button>
        </>
      ) : type === "secondary" ? (
        <>
          <button
            onClick={onClick}
            className={`py-3 px-10 w-full rounded-lg text-gray-700 border border-[#F5E28C] hover:bg-[#F5E28C] transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-10 duration-300 ${children}`}
          >
            {text}
          </button>
        </>
      ) : (
        <>
          <button
            onClick={onClick}
            className={`py-3 px-10 w-full rounded-lg text-white border-0 bg-darkBlue transition ease-in-out delay-150 bg-blue-500 hover:scale-10 hover:bg-darkBlue duration-300 ${children} `}
          >
            {text}
          </button>
        </>
      )}
    </>
  );
};

export default Button;
