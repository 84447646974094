import Input from "./Input";
import Button from "./Button";

const FooterCTA = () => {
  return (
    <div className="">
      <p className="mt-4 text-sm text-gray-700 md:text-gray-500">
        Please fill in your details below to receive our newsletter. Your
        details will be added to our mailing list. You can unsubscribe at any
        time.
      </p>
      <>
        <div className="md:col-span-2 md:mt-0">
          <form action="#" method="POST">
            <div className="py-5">
              <div className="w-full grid grid-cols-6 gap-4">
                <Input
                  label="Newsletter"
                  name="newsletter"
                  inputLength="large"
                  placeholder="you@example.com"
                  type="email"
                  field="input"
                  autoComplete="true"
                  children={"md:text-gray-500"}
                />
                <div className="col-span-6">
                  <Button text="Subscribe" children={"hover:bg-blue"} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    </div>
  );
};

export default FooterCTA;
