import { React, useState } from 'react'
import { Link } from 'react-scroll';
import Button from './Button';
import { Bars3BottomRightIcon, XMarkIcon, } from '@heroicons/react/24/outline'
import Logo from './Logo'
import SocialPlatforms from './SocialPlatforms';
import Modal from './Modal';

const Nav = () => {
  const [nav, setNav] = useState(false);
  const handleNavOpen = () => setNav(!nav)
  const handleNavClose = () => setNav(!nav)

  const [showView, setShowView] = useState(false);

  const handleShowModal = (e) => {
    e?.preventDefault();
    setShowView(true);
    console.log('showing');
  }

  return (
    <>
      <header>
        <nav className='px-[2rem] md:px-[7.5rem]'>
          <div className=' h-20 items-center flex justify-between'>
            <Logo children='text-blue' />
            <ul className='hidden md:flex gap-3'>
              <li>
                <Link to='about' className='block text-sm text-gray-700 py-2 px-4 hover:text-blue hover:cursor-pointer' smooth={true} offset={50} duration={500}>About</Link>
              </li>
              <li>
                <Link to='whattoexpect' className='block text-sm text-gray-700 py-2 px-4 hover:text-blue hover:cursor-pointer' smooth={true} offset={-50} duration={500}>What to expect</Link>
              </li>
              <li>
                <Link to='testimonials' className='block text-sm text-gray-700 py-2 px-4 hover:text-blue hover:cursor-pointer' smooth={true} offset={50} duration={500}>Testimonials</Link>
              </li>
              <li>
                <Link to='faqs' className='block text-sm text-gray-700 py-2 px-4 hover:text-blue hover:cursor-pointer' smooth={true} offset={50} duration={500}>FAQs</Link>
              </li>
            </ul>
            <div className='hidden md:block'>
              <Button type={'secondary'} text="Let's connect" onClick={handleShowModal} />
            </div>
            <div className='md:hidden' onClick={handleNavOpen}>
              {
                !nav ? <Bars3BottomRightIcon className='h-6 text-blue hover:text-darkBlue hover:cursor-pointer' /> : <XMarkIcon className='h-6 text-blue hover:text-darkBlue hover:cursor-pointer' />
              }
            </div>
          </div>
        </nav>

        <nav className={!nav ? 'hidden' : 'absolute w-full h-screen pt-6 bg-white/90'}>
          <ul className='ml-[3.5rem]  pr-9 '>
            <li onClick={handleNavClose}>
              <Link to='about' className='block mb-10 text-sm font-medium text-gray-700 py-2 px-2 hover:text-blue hover:cursor-pointer' onClick={handleNavClose} offset={50} smooth={true} duration={500}>About</Link>
            </li>
            <li onClick={handleNavClose}>
              <Link to='whattoexpect' className='block mb-10 text-sm font-medium text-gray-700 py-2 px-2 hover:text-blue hover:cursor-pointer' onClick={handleNavClose} offset={-50} smooth={true} duration={500}>What to expect</Link>
            </li>
            <li onClick={handleNavClose}>
              <Link to='testimonials' className='block mb-10 text-sm font-medium text-gray-700 py-2 px-2 hover:text-blue hover:cursor-pointer' onClick={handleNavClose} offset={50} smooth={true} duration={500}>Testimonials</Link>
            </li>
            <li onClick={handleNavClose}>
              <Link to='faqs' className='block mb-10 text-sm font-medium text-gray-700 py-2 px-2 hover:text-blue hover:cursor-pointer' onClick={handleNavClose} smooth={true} offset={50} duration={500}>FAQs</Link>
            </li>
            <div >
              <Button
                type={'secondary'}
                text="Let's connect"
                onClick={handleShowModal}
              />
            </div>
            <div className='mt-8'>
              <SocialPlatforms onClick={handleNavClose} />
            </div>
          </ul>
        </nav>

        <>
          <Modal show={showView} setShow={setShowView} />
        </>
      </header>

    </>
  )
}





export default Nav