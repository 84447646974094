
export default function Input({
  label,
  optionalLabel,
  type,
  field,
  required,
  name,
  autoComplete,
  placeholder,
  inputLength,
  children
}) {
  return (
    <>
      {field === "input" ? (
        <>
          <div className={inputLength === "small" ? (
            "col-span-6 sm:col-span-6 lg:col-span-2"
          ) : inputLength === "medium" ? (
            "col-span-6 sm:col-span-3"
          ) : inputLength === "large" ? ("col-span-6") : "col-span-6 sm:col-span-6 lg:col-span-2"}>
            <label
              htmlFor={name}
              className={`block text-sm font-medium text-gray-700 ${children} `}
            >
              {label}

            </label>
            <input
              type={type}
              name={name}
              id={name}
              required={required}
              autoComplete={autoComplete}
              placeholder={placeholder}
              className="mt-1 block w-full py-2 px-2 focus:border-darkBlue rounded-md border border-blue shadow-sm placeholder:text-gray-400 focus:border-0 focus:ring-darkBlue sm:text-sm"

            />
          </div>
        </>
      ) : field === "textarea" ? (
        <>
          <div className={inputLength === "small" ? ("col-span-6 sm:col-span-6 lg:col-span-2") : inputLength === "medium" ? ("col-span-6 sm:col-span-3") : inputLength === "large" ? ("col-span-6") : "col-span-6 sm:col-span-6 lg:col-span-2"}>
            <label
              htmlFor={name}
              className="block text-sm font-medium text-gray-700"
            >
              {label}
              <span className="mt-2 text-sm text-red-400">
                {optionalLabel}
              </span>
            </label>
            <div className="mt-1">
              <textarea
                id={name}
                name={name}
                rows={3}
                placeholder={placeholder}
                className="mt-1 block w-full py-2 px-2 focus:border-darkBlue rounded-md border border-blue shadow-sm placeholder:text-gray-400 resize-none focus:border-0 focus:ring-darkBlue sm:text-sm"
                defaultValue={""}

              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={inputLength === "small" ? ("col-span-6 sm:col-span-6 lg:col-span-2") : inputLength === "medium" ? ("col-span-6 sm:col-span-3") : inputLength === "large" ? ("col-span-6") : "col-span-6 sm:col-span-6 lg:col-span-2"}>
            <label
              htmlFor={name}
              className="block text-sm font-medium text-gray-700"
            >
              {label}
            </label>
            <input
              type={type}
              name={name}
              id={name}
              autoComplete={autoComplete}
              placeholder={placeholder}
              className="mt-1 block w-full py-2 px-2 focus:border-darkBlue rounded-md border border-blue shadow-sm placeholder:text-gray-400 focus:border-0 focus:ring-darkBlue sm:text-sm"

            />
          </div>
        </>
      )}
    </>
  )
}