import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <>
      <section
        id='page not found'
        className='py-20 md:py-28 bg-slate-50 px-9 md:px-[7.5rem]'
      >
        <div className='flex flex-col items-center'>
          <h1 className='font-display text-3xl tracking-tight text-slate-900 sm:text-4xl'>Uh-Oh...</h1>
          <p className='font-display leading-7 text-slate-900 text-center md:max-w-xl my-2  md:text-2xl'>The page you are looking for may have been moved, deleted or possibly never existed.
          </p>
          <div className='mt-6 transition  ease-in-out delay-150 duration-300 hover:-translate-y-1'>
            <Link className='px-8 py-3 bg-slate-200 hover:bg-slate-300 rounded-lg ring-0 border-0 ' to='/'> Go to home</Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default PageNotFound