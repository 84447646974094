import Logo from "./Logo";
import SocialPlatforms from "./SocialPlatforms";
import FooterCTA from "./FooterCTA";
import { Link } from "react-router-dom";
import Form from "./Form";
import { useState } from "react";
import Modal from "./Modal";

const Footer = () => {
  const [showView, setShowView] = useState(false);

  const handleShowModal = (e) => {
    e?.preventDefault();
    setShowView(true);
    console.log("showing");
  };

  let dt = new Date();

  return (
    <>
      <div className="md:hidden mt-[-1rem] px-9 pb-20 bg-slate-50">
        <FooterCTA />
      </div>
      <footer
        id="footer"
        aria-label="What our clients are saying"
        className="bg-yellow py-20 px-9 md:py-20 md:px-[7.5rem]"
      >
        <div className="md:flex md:gap-40">
          <div>
            <Logo children="text-darkBlue" />
            <p className="mt-4 text-sm text-gray-700">
              Please fill in your details below to receive our newsletter. Your
              details will be added to our mailing list. You can unsubscribe at
              any time.
            </p>
            <div className="mt-5">
              <p>
                Email:{" "}
                <a href="mailto:https://rhoda@coachspicey.com">
                  rhoda@coachspicey.com
                </a>
              </p>
              <p>Phone: +347-792-3759</p>
            </div>
            <div className="mt-8 sm:hidden md:block">
              <SocialPlatforms />
            </div>
          </div>

          <div className="w-72">
            <ul className="mt-4 sm:mt-10">
              <li
                className="mb-4 text-gray-500 py-1 hover:cursor-pointer hover:text-gray-400"
                onClick={handleShowModal}
              >
                Contact
              </li>
              <li className="mb-4">
                <Link
                  to="/terms-and-conditions"
                  className="py-1 text-gray-500 hover:text-gray-400"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  className="py-1 text-gray-500 hover:text-gray-400"
                  to="/privacy-policy "
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          <div className="mt-12 md:hidden">
            <SocialPlatforms />
          </div>

          <div className="sm:hidden md:block">
            <FooterCTA />
          </div>
        </div>
      </footer>
      <div className="bg-darkBlue py-4">
        <p className="text-white/80 text-center text-sm">
          Copyright {dt.getFullYear()} Coach Spicey | Designed by{" "}
          <a
            className="text-yellow hover:cursor-pointer hover:text-darkYellow"
            href="mailto:scortip@gmail.com"
          >
            PAISATS
          </a>
        </p>
      </div>
      <Modal show={showView} setShow={setShowView}>
        <Form />
      </Modal>
    </>
  );
};

export default Footer;
