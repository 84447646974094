const WhatToExpect = () => {
  const wte = [
    {
      heading: "A personalized approach",
      body: "Take the time to understand your unique needs and goals, and design a coaching program that is tailored to your specific situation.",
      color: "darkBlue",
      background: "bg-card1",
      textColor: "text-white",
    },
    {
      heading: "Support and encouragement",
      body: "Being a supportive and encouraging partner, helping you to stay motivated and focused on your goals.",
      color: "lightGreen",
      background: "bg-card2",
      textColor: "text-gray-700",
    },
    {
      heading: "A safe, non-judgmental space",
      body: "Provide a safe and non-judgmental space for you to explore your thoughts, feelings, and challenges, and to work through any obstacles that may be holding you back.",
      color: "yellow",
      background: "bg-card3",
      textColor: "text-gray-700",
    },
    {
      heading: "Tools and strategies",
      body: "Provide you with a range of tools and strategies to help you make progress and achieve your goals.",
      color: "darkBlue",
      background: "bg-card4",
      textColor: "text-white",
    },
    {
      heading: "Ongoing guidance and accountability",
      body: "Support you every step of the way, providing ongoing guidance and accountability to help you stay on track and achieve your desired outcomes.",
      color: "lightGreen",
      background: "bg-card5",
      textColor: "text-gray-700",
    },
    {
      heading: "One-on-one sessions",
      body: "You can expect to work with me in individual sessions, either in person or online. These sessions will typically last 60-90 minutes, and may be held weekly or biweekly.",
      color: "yellow",
      background: "bg-card6",
      textColor: "text-gray-700",
    },
  ];
  return (
    <>
      <section
        name="whattoexpect"
        id="what to expect"
        aria-label="What our clients are saying"
        className="bg-white py-20 sm:py-32 "
      >
        <div>
          <article className="px-4 md:px-[17rem] ">
            <h1 className="">What to expect</h1>
            <p
              id="faq-title"
              className="mt-4 text-lg tracking-tight text-slate-700"
            >
              Every client has the capacity to know the path to success. <br />{" "}
              The coach’s role is to help the client access his or her passion
              and genius.
            </p>
          </article>
        </div>
        <div className="md:px-[7.5rem] mt-10 w-full flex flex-col justify-center items-center">
          <div className="mt-4 px-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
            {wte.map((item, itemIndex) => (
              <ul
                key={itemIndex}
                className="mx-auto bgImg rounded-lg max-w-lg "
              >
                <li className="overlay h-full rounded-lg max-w-lg p-6">
                  <h3 className="font-display mt-2 pt-2 text-xl leading-7 text-slate-50">
                    {item.heading}
                  </h3>
                  <p className="mt-2 text-lg leading-6 text-slate-50">
                    {item.body}
                  </p>
                </li>
              </ul>
            ))}
          </div>

          {/* <h1 className="mt-16">Second cards</h1> */}
          {/* <div className="mt-4 px-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
            {wte.map((item, itemIndex) => (
              <ul key={itemIndex} className="mx-auto  rounded-lg  max-w-lg ">
                <li className="rounded-lg max-w-lg ">
                  <div
                    className={`${item.background} bg-no-repeat bg-cover bg-center h-52 max-w-lg rounded-tr-lg rounded-tl-lg`}
                  ></div>
                  <div
                    className={`bg-${item.color} p-4 rounded-bl-lg rounded-br-lg`}
                  >
                    <h3
                      className={`font-display text-lg leading-7 ${item.textColor}`}
                    >
                      {item.heading}
                    </h3>
                    <p className={`mt-2 text-sm ${item.textColor}`}>
                      {item.body}
                    </p>
                  </div>
                </li>
              </ul>
            ))}
          </div> */}
        </div>
      </section>
    </>
  );
};

export default WhatToExpect;
