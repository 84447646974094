import React from 'react'
import { Link } from 'react-router-dom'

const Logo = ({ children }) => {
  return (
    <div>
      <Link to='/' className={`logo text-2xl md:text-3xl ${children}`}>COACHSPICEY</Link>
    </div>
  )
}

export default Logo