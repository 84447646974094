
import rhodaDesktop from './../assets/desktop/rhoda.png'
import rhodaMobile from './../assets/mobile/rhoda.png'

const About = () => {

  return (
    <>
      <section
        name="about"
        id="about"
        aria-label="about me"
        className="bg-slate-50 pt-20 sm:pt-32"
      >
        <div name="about" className='flex sm:flex-col items-center justify-center gap-6 md:gap-10 md:flex-row px-9 md:px-[7.5rem]'>
          <h2
            id="about-title"
            className="md:hidden font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Welcome to Coach Spicey for
            Hoslistic Life Coaching!
          </h2>
          <div className='text-left'>
            <img src={window.innerWidth > 375 ? rhodaDesktop : rhodaMobile} width='472' height='602' loading='lazy' title='Coach Spicey - author' alt="Coach Spicey - author" />
          </div>
          <div className='md:w-1/2'>
            <h2 className='sm:hidden md:block mb-6 font-display text-3xl tracking-tight text-slate-900 sm:text-4xl'>
              Welcome to Coach Spicey <br /> for
              Hoslistic Life Coaching!
            </h2>
            <p className='md:w-5/6 md:text-xl font-display text-lg leading-7 text-slate-900'>
              Coach Spicey born Rhoda Francis on the island of Grenada, known as the Island of Spice. At a young age, she moved to the United States to live with her father and his family. Rhoda grew up as the eldest child in a West Indian household. At 18, she found herself alone and navigating the challenges of adulthood. By 23, she was married and had a child. Rhoda worked in nursing for 20 years, supporting her family financially on her own while also caring for her son, who has autism. At 40, she suffered a spinal injury while caring for a patient, which prompted her to pursue her true passion of helping others understand themselves and become the best versions of themselves. Through her coaching, Coach Spicey aims to help individuals unlock their inner selves and live fulfilling lives.
            </p>
            <h3 className='mt-4 italic'>Coach Spicey </h3>
            <small>~Director</small>
          </div>
        </div>
      </section>
    </>
  )
}

export default About