import Home from './components/Home'
import { Routes, Route } from 'react-router-dom';
import PageNotFound from './components/PageNotFound';
import Nav from './components/Nav';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions.jsx';

function App() {
  return (
    <>
      <Nav />
      <ErrorBoundary>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsConditions />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </ErrorBoundary>
      <Footer />
    </>
  );
}

export default App;
