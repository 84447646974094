import React from 'react'
import Button from './Button'

const ScheduleMeeting = () => {
  return (
    <>

      <section
        id="testimonials"
        aria-label="What our clients are saying"
        className="bg-schedule-meeting-mobile md:bg-schedule-meeting-desktop bg-no-repeat bg-cover bg-center py-20 sm:py-32 text-center"
      >
        <div className='px-4 flex flex-col items-center justify-center'>

          <h2 className='text-white md:text-4xl md:px-64 md:leading-snug'>Together, we will explore mindfulness, self-care, personal growth, relationships, health and wellness, creativity, spirituality, and work-life balance to help you live your best life.</h2>
          <p className='text-slate-200 mt-4 mx-auto mb-14 md:w-2/5 md:text-xl'>Book a meeting with Coach Spicey to chat through your options. Or send an email using the contact form.
          </p>
          <div className='md:w-72 flex justify-center' target='_blank' rel='noreferrer'>
            <a href="https://calendly.com/coach-spicey-/30min">
              <Button type={'primary'} text='SCHEDULE MEETING' />
            </a>
          </div>
        </div>

      </section>
    </>
  )
}

export default ScheduleMeeting