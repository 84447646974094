import { FaHandsHelping } from "react-icons//fa";
import { GiProgression } from "react-icons/gi";
import { RiUserHeartFill } from "react-icons/ri";

const Mission = () => {
  return (
    <>
      <section
        name="Mission"
        id="mission"
        aria-label="mission"
        className="bg-slate-50 pt-20 sm:pt-32"
      >
        <div className="grid grid-cols-2 md:grid-cols-3 grid-rows-2">
          <div className="bg-darkBlue order-0 px-4 py-10 md:px-20 md:py-28">
            <h3 className="text-white md:text-3xl mb-4 md:mb-6">
              Identify <br /> Values
            </h3>
            <p className="text-white md:text-xl text-sm">
              Assist clients in identifying their values, strengths, and areas
              for growth.
            </p>
          </div>
          <div className="bg-darkBlue order-1 flex justify-center items-center">
            <RiUserHeartFill className="text-8xl md:text-9xl text-gray-300" />
          </div>
          <div className="bg-lightGreen order-3 md:order-2 px-4 py-10 md:px-20 md:py-28">
            <h3 className="text-gray-700 md:text-3xl mb-4 md:mb-6">
              Improve <br /> Client
            </h3>
            <p className="text-gray-700 md:text-xl text-sm">
              Improve clients' overall well-being by addressing various areas of
              their lives.
            </p>
          </div>
          <div className="bg-yellow order-2 md:order-3 flex justify-center items-center">
            <GiProgression className="text-8xl md:text-9xl text-gray-700" />
          </div>
          <div className="bg-yellow order-4 px-4 py-10 md:px-20 md:py-28">
            <h3 className="text-gray-700 md:text-3xl mb-4 md:mb-6">
              Best <br /> Lives
            </h3>
            <p className="text-gray-700 md:text-xl text-sm">
              Help clients live their best lives and reach their full potential.
            </p>
          </div>
          <div className="bg-lightGreen order-5 flex justify-center items-center">
            <FaHandsHelping className="text-8xl md:text-9xl text-gray-700" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Mission;
