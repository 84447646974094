const faqs = [
  [
    {
      question: 'What is Holistic Life Coaching?',
      answer:
        'A holistic life coach is a professional who helps individuals achieve their personal and professional goals and live a more balanced and fulfilling life. This may involve working with clients on various areas of their lives, such as mindfulness, self-care, personal growth, relationships, health and wellness, creativity, spirituality, and work-life balance.',
    },
    {
      question: 'What is the difference between Life Coaching and Holistic Life Coaching?',
      answer: "Life coaching typically focuses on specific areas of an individual's life and uses a more directive approach, while holistic life coaching takes a broader, more holistic approach and works with clients to identify their values and create personalized plans to reach their desired outcomes.",
    },
    {
      question: 'What can I expect from working with a holistic life coach?',
      answer:
        'Working with a holistic life coach typically involves one-on-one sessions, either in person or online. During these sessions, your coach will work with you to understand your goals and create a personalized plan to help you achieve them. Your coach will also provide support and guidance as you work towards your goals, and may ask you to complete certain tasks or exercises between sessions.',
    },
  ],
  [
    {
      question: 'How often will I meet with my coach?',
      answer:
        "The frequency of coaching sessions will depend on your needs and goals. Some coaches offer weekly sessions, while others may suggest biweekly or monthly sessions. It's important to discuss your scheduling needs with your coach and find a frequency that works for you.",
    },
    {
      question: 'How long does it take to work with a holistic life coach?',
      answer:
        'The length of the coaching process can vary depending on your goals and needs. Some people may see significant progress in just a few sessions, while others may need longer-term support. Your coach will work with you to determine the best course of action for your individual situation.',
    },
    {
      question:
        'Is working with a holistic life coach right for me?',
      answer:
        'Coaching can be a helpful tool for anyone looking to make positive changes in their life and achieve their goals. However, if you are experiencing severe mental health issues or are in crisis, it may be more appropriate to seek out therapy or other forms of support. Your coach can help you determine if coaching is the right fit for you',
    },
  ],
  [
    {
      question: 'Is coaching confidential?',
      answer:
        'Yes, coaching is generally considered a confidential process. Your coach will respect your privacy and will not share your personal information without your consent.',
    },
    {
      question: 'How is a holistic life coach different from a traditional coach or therapist?',
      answer: 'A holistic life coach takes a holistic approach to coaching, considering the whole person and all aspects of their life, while traditional coaches may focus on a specific area and use a more directive approach, and therapists typically focus on mental health and address psychological issues or challenges.',
    },
  ],
]

export default function Faqs() {
  return (
    <section
      name='faqs'
      id="faqs"
      aria-labelledby="frequently asked questions"
      className="relative overflow-hidden"
    >
      <div className="bg-faqs bg-no-repeat bg-cover bg-center py-28">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 id="faq-title" className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
              Frequently asked questions
            </h2>
            <p className="mt-4 text-lg tracking-tight text-slate-700">
              If you can’t find what you’re looking for, send an email and I will get back to you.
            </p>
          </div>
          <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
            {faqs.map((column, columnIndex) => (
              <li key={columnIndex}>
                <ul className="flex flex-col gap-y-8">
                  {column.map((faq, faqIndex) => (
                    <li key={faqIndex}>
                      <h3 className="font-display text-lg leading-7 text-slate-900">
                        {faq.question}
                      </h3>
                      <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}
