
import Hero from './Hero'
import About from './About'
import Mission from './Mission'
import WhatToExpect from './WhatToExpect'
import ScheduleMeeting from './ScheduleMeeting'
import Testimonials from './Testimonials'
import FAQs from './FAQs'

const Home = () => {
  return (
    <>
      <Hero />
      <main>
        <About />
        <Mission />
        <WhatToExpect />
        <Testimonials />
        <ScheduleMeeting />
        <FAQs />
      </main>
    </>
  )
}

export default Home