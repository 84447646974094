import Button from "./Button";

const Hero = () => {
  return (
    <>
      <section id="hero" aria-label="hero">
        <div className="bg-hero-mobile md:bg-hero-desktop bg-cover bg-no-repeat bg-center py-36 px-9 md:px-[7.5rem]">
          <h1 className="text-white md:text-7xl leading-normal md:leading-tight md:w-3/5 mb-4">
            Supporting you on your journey towards personal growth and
            fulfillment.
          </h1>
          <p className=" mb-10 text-white text-2xl">
            Schedule an appointment to see how coaching will improve your life
          </p>
          <div className="w-72">
            <a
              href="https://calendly.com/coach-spicey-/30min"
              target="_blank"
              rel="noreferrer"
            >
              <Button type={"primary"} text="SCHEDULE MEETING" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
