import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import calendly from "./../assets/mobile/calendly.png";
import calendlyDesktop from "./../assets/desktop/calendly.png";

const SocialPlatforms = ({ onClick }) => {
  return (
    <div>
      <div>
        <ul className="flex gap-4 mt-6">
          <li
            className="bg-white border border-gray-300 px-3 py-2  rounded-lg hover:border-gray-500 hover:cursor-pointer"
            title="Calendly"
            onClick={onClick}
          >
            <a
              href="https://calendly.com/coach-spicey-/30min"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={window.innerWidth > 375 ? calendlyDesktop : calendly}
                width="33"
                height="33"
                loading="lazy"
                title="Calendly"
                alt="calendly for schedule meetings"
              />
            </a>
          </li>

          <li
            className="bg-white border border-gray-300 px-4 py-2 rounded-lg hover:border-gray-500 hover:cursor-pointer"
            title="Instagram"
            onClick={onClick}
          >
            <a
              href="https://www.instagram.com/coachspicey/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className="text-[#C9379A] text-xl" />
            </a>
          </li>
          <li
            className="bg-white border border-gray-300 px-4 py-2 rounded-lg hover:border-gray-500 hover:cursor-pointer"
            title="Youtube"
            onClick={onClick}
          >
            <a href="https://youtube.com/@CoachSpicey">
              <FaYoutube className="text-red-500 text-xl" />
            </a>
          </li>
          <li
            className="bg-white border border-gray-300 px-4 py-2 rounded-lg hover:border-gray-500 hover:cursor-pointer"
            title="TikTok"
            onClick={onClick}
          >
            <a href="https://tiktok.com/@coachspicey">
              <FaTiktok className="text-slate-900 text-xl " />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SocialPlatforms;
