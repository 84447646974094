const testimonials = [
  [
    {
      content:
        "I was struggling with stress and burnout when I started working with Coach Spicey. She helped me develop self-care practices and taught me how to prioritize my well-being. I now have more energy and feel more in control of my life.",
      author: {
        name: "Sheryl Berge",
        role: "CEO at Lynch LLC",
      },
    },
    {
      content:
        "Working with Coach Spicey has completely changed my life. She has helped me identify my values and strengths, and has supported me in setting and achieving my goals. I am now living a more balanced and fulfilling life, and I feel more confident and empowered than ever before.",
      author: {
        name: "Amy Hahn",
        role: "Director at Velocity Industries",
      },
    },
  ],
  [
    {
      content:
        "I was at a crossroads in my career and didn't know what direction to take. Coach Spicey helped me explore my options and make a plan to pursue my dream job. I am now doing work that I love and feel fulfilled and purposeful in my career.",
      author: {
        name: "Leland Kiehn",
        role: "Founder of Kiehn and Sons",
      },
    },
    {
      content:
        "I was in a difficult relationship and wasn't sure how to navigate the challenges we were facing. Coach Spicey helped me communicate more effectively and build a stronger, more positive connection with my partner. Our relationship is now stronger and more loving than ever before.",
      author: {
        name: "Erin Powlowski",
        role: "COO at Armstrong Inc",
      },
    },
  ],
  [
    {
      content:
        "I now feel like I have the strength to move on and am excited for my future.” Coach Spicey gave me the tools to improve my self – confidence and develop new ways of thinking. She helped me to make positive changes to my life. Thanks Spicey.",
      author: {
        name: "Peter Renolds",
        role: "Founder of West Inc",
      },
    },
    {
      content:
        "I was struggling with negative self-talk and low self-esteem when I started working with Coach Spicey. She helped me identify the root causes of these issues and taught me how to cultivate self-compassion and confidence. I now feel much more positive about myself and my abilities.",
      author: {
        name: "Amy Hahn",
        role: "Director at Velocity Industries",
      },
    },
  ],
];

function QuoteIcon(props) {
  return (
    <svg aria-hidden="true" width={105} height={78} {...props}>
      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
    </svg>
  );
}

export default function Testimonials() {
  return (
    <section
      name="testimonials"
      id="testimonials"
      aria-label="What our clients are saying"
      className="bg-slate-50 py-20 sm:py-32"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="max-w-2xl">
          <h2 className="font-display text-left text-3xl tracking-tight text-slate-900 sm:text-4xl">
            Loved by clients worldwide.
          </h2>
          <p className="mt-4 text-left text-lg tracking-tight text-slate-700">
            I help clients improve their overall well-being by addressing
            various areas of their life, such as mindfulness, self-care,
            personal growth, relationships, health and wellness, creativity,
            spirituality, and work-life balance.
          </p>
        </div>
        <ul className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3">
          {testimonials.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul className="flex flex-col gap-y-6 sm:gap-y-8">
                {column.map((testimonial, testimonialIndex) => (
                  <li key={testimonialIndex}>
                    <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                      <QuoteIcon className="absolute top-6 left-6 fill-lightGreen/10" />
                      <blockquote className="relative">
                        <p className="text-lg tracking-tight text-slate-900">
                          {testimonial.content}
                        </p>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                        <div>
                          <div className="font-display text-base text-slate-900">
                            {testimonial.author.name}
                          </div>
                          <div className="mt-1 text-sm text-slate-500">
                            {testimonial.author.role}
                          </div>
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
